import React from 'react'
import {Link} from 'react-router-dom'
import { AiOutlineGithub } from 'react-icons/ai';
import { IoLogoLinkedin } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import {motion} from 'framer-motion'

export default function Header() {
    return (
        <div className="header-color">
            <div className="container">
            <div className="grid-x header">
                <motion.div className="cell small-6 header__logo"
                    initial={{ scale: 1, opacity: 0, y:'-200px'}}
                    animate={{ scale: 1, opacity: 1, y:0 }}
                    transition={{
                    type: 'spring', damping:10, mass:0.75, stiffness: 100, duration: 0.5, delay: 0
                    }}>
                <Link to="/">peter <span className="header__logo-light">wind</span></Link>
                </motion.div>
                <div className="cell small-6 menu">
                    <div className="grid-x">
                        <div className="cell small-4 header__social"><motion.a href="https://github.com/schaadt"
                            initial={{ scale: 1, opacity: 0, y:'-200px'}}
                            animate={{ scale: 1, opacity: 1, y:0 }}
                            transition={{
                            type: 'spring', damping:10, mass:0.75, stiffness: 100, duration: 0.5, delay: 0.2
                            }}>
                        <AiOutlineGithub /></motion.a></div>
                        <div className="cell small-4 header__social"><motion.a href="https://www.linkedin.com/in/peterscaadtwind"
                            initial={{ scale: 1, opacity: 0, y:'-200px'}}
                            animate={{ scale: 1, opacity: 1, y:0 }}
                            transition={{
                            type: 'spring', damping:10, mass:0.75, stiffness: 100, duration: 0.5, delay: 0.4
                            }}><IoLogoLinkedin /></motion.a></div>
                        <div className="cell small-4 header__social"><motion.a href="mailto:peterschaadtwind@gmail.com"
                            initial={{ scale: 1, opacity: 0, y:'-200px'}}
                            animate={{ scale: 1, opacity: 1, y:0 }}
                            transition={{
                            type: 'spring', damping:10, mass:0.75, stiffness: 100, duration: 0.5, delay: 0.6
                            }}><MdEmail /></motion.a></div>
                    </div>
                    </div>
                 </div>
            </div>
        </div>
    )
}
