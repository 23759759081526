import React from 'react'
import {Link} from 'react-router-dom'
import Skills from './Skills'
import About from './About'
import {motion} from 'framer-motion'


/* const containerVariants = {
  hidden: { 
    opacity: 0, 
  },
  visible: { 
    opacity: 1, 
    transition: { delay: 1.5, duration: 1.5 }
  },
  exit: {
    x: "-100vh",
    transition: { ease: 'easeInOut' }
  }
};
 */

export default function List({ products }) {
    return (
      <motion.div className="container"
      >
        <div className="grid-x products">



       <div className="cell intro">   
       <motion.h1
       initial={{ scale: 1, opacity: 0, x:0}}
        animate={{ scale: 1, opacity: 1, x:0 }}
        transition={{
          ease: 'circIn', duration: 0.5, delay: 0
        }}
        >Hej, mit navn er Peter Wind
       </motion.h1>
       <motion.p
        initial={{ scale: 1, opacity: 0, x:0}}
        animate={{ scale: 1, opacity: 1, x:0 }}
        transition={{
          ease: 'circIn', duration: 0.5, delay: 0
        }}
       >Jeg brænder for at udvikle web applikationer  <br /> samt at skabe gode brugeroplevelser <br />   
        </motion.p>

        {/* <Codebox /> */}
        <About />
        <Skills />  

        </div>  

        <h3>Seneste Projekter</h3> 
        <div className="grid-x">

        
        {products.map(({id, name, slug, text, photo, stacks }) => (
        <div className="cell small-12 medium-6">
                <div className="list-intro" key={id}>
                  <div className="link-header">
                  <Link to={`/projekter/${slug}`}>{name}</Link>
                  <div className="stack">                 
                  {stacks}
                  </div>
                  </div>

                  <div className="image-link">
                  <Link to={`/projekter/${slug}`}><img className="images" src={photo.url} alt="something" /></Link>
                  </div>
                  <br />
                </div>
        </div>
        ))}
        </div>

            
        </div>
        </motion.div>
    )
}
