import React from 'react'

export default function Skills() {
    return (
        <div className="skills">
            <h3>Kompetencer</h3>
           
            <div className="grid-x skills__coding">
                <div className="cell small-12 medium-12 large-3 skills__skill">HTML & CSS / SASS</div>
                <div className="cell small-12 medium-12 large-3 skills__skill">Javascript ES6</div>
                <div className="cell small-12 medium-12 large-3 skills__skill">React JS / Next JS</div>
                <div className="cell small-12 medium-12 large-3 skills__skill">Vue JS</div>
                <div className="cell small-12 medium-12 large-3 skills__skill">Firebase</div>
                <div className="cell small-12 medium-12 large-3 skills__skill">PHP & SQL</div>
                <div className="cell small-12 medium-12 large-3 skills__skill">WordPress / GraphCMS</div>
                <div className="cell small-12 medium-12 large-3 skills__skill">Git</div>
            </div>
        </div>
    )
}

