import React from 'react'
import Pdf from '../peterwind.pdf';
import {motion} from 'framer-motion'

const buttonVariants = {
    hover: {
      scale: 1.1,
      textShadow: "0px 0px 8px rgb(255,255,255)",
      boxShadow: "0px 0px 8px rgb(255,255,255)",
      transition: {
        duration: 0.3,
        yoyo: 5
      }
    }
  }


export default function About() {
    return (
        <motion.div className="about"
        initial={{ scale: 1, opacity: 0, x:0}}
        animate={{ scale: 1, opacity: 1, x:0 }}
        transition={{
          ease: 'circIn', duration: 0.5, delay: 0
        }}
        >

            <div className="grid-x">
              <p>Front-End udvikler med base i Esbjerg.  <br />
                  Jeg udvikler web applikationer i moderne frameworks
                  <br /><a href = {Pdf}>
                  <motion.button
                    variants={buttonVariants}
                    whileHover="hover"
                     >
          HENT MIT CV
        </motion.button>
                  </a>
              </p>  
            </div>
        </motion.div>
    )
}

