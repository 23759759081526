import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { request } from 'graphql-request';
import { useLocation } from 'react-router-dom';
import Header from './components/layouts/Header';
import Product from './components/Product';
import List from './components/List';
import ScrollToTop from './components/ScrollToTop';
import './App.scss';
import Footer from './components/layouts/Footer';
import { AnimatePresence } from 'framer-motion';

function App() {
  //const location = useLocation();
const [products, setProducts] = useState(null)

  useEffect(() => {
    const fetchProducts = async () => {
      const { products } = await request(
        'https://api-eu-central-1.graphcms.com/v2/ckkla6avpap0v01xmbc4k7u03/master',
        `
      { 
        products {
          id
          name
          slug
          text
          stacks
          links
          photo {
            id
            url
          }
          photo2 {
            id
            url
          }
          content {
            raw
            html
            markdown
            text
          }
        }
      }
    `);
    setProducts(products);
    };
    fetchProducts();
  }, []);

  return (
    <div className="app">
      <Router>
      <ScrollToTop />
      <Header />
        {!products ? (
          ''
        ) : (
          <React.Fragment>
            <AnimatePresence exitBeforeEnter>
              <Switch>
                <Route exact path="/projekter/:slug">
                  <Product products={products} />
                </Route>  
                <List products={products} />
              </Switch>
            </AnimatePresence>
          </React.Fragment>
        )}
        <Footer />
      </Router>
    </div>
  );
}

export default App;