import React from 'react';
import { useParams } from 'react-router-dom';
import {motion} from 'framer-motion'

function Product({ products }) {
  const { slug } = useParams();
  const product = products.find((product) => product.slug === slug);

  return (
  <>  
    <motion.div className="container"
      initial={{ scale: 1 , x: '-90vw', opacity: 0 }}
      animate={{ scale: 1, x: 0, opacity: 1  }}
      transition={{
        type: 'spring', damping:10, mass:0.75, stiffness: 100, duration: 0.5, delay: 0
    }}
    >
      <motion.div className="grid-x products"

      >
        <h1>{product.name}</h1>
        <div className="cell small-12 text">
        <div className="stacks">{product.stacks}</div>
        <div className="links">
        <a href={product.links} target="_blank">{product.links}</a>
          </div>
        <p dangerouslySetInnerHTML={{ __html: product.content.html }} />
      </div>
      </motion.div>
      <motion.div className="grid-x products">
        <div className="cell small-12 preview"><img className="images" src={product.photo.url} alt="something" /></div>
        { product.photo2 ? <div className="cell small-12 preview"><img className="images" src={product.photo2.url} alt="something" /> </div> : '' }
      </motion.div>
    </motion.div>
  </>
  );
}

export default Product;